import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { MainLayout } from '../../components/layouts/main-layout';
import { AddBreadcrumbsItem } from '../../components/shared/breadcrumbs/breadcrumbs';
import { SecuredPage } from '../base/secured-page';
import { EmotionEpisodeEditor } from './parts/emotion-episode/emotion-episode-editor';
import { EpisodeEditor } from './parts/episode/episode-editor';
import { NarrativeEditor } from './parts/narrative/narrative-editor';
import { NarrativeList } from './parts/narrative/narratives-list';
import { ParticipantDataEditor } from './parts/participant-data/participant-data-editor';
import { SceneEditor } from './parts/scene/scene-editor';


export function NarrativesManagementPage(props: RouteComponentProps) {

    return (
        <SecuredPage pageName="narratives" {...props} >

            <AddBreadcrumbsItem
                title="Home"
                url="/"
            />
            <AddBreadcrumbsItem
                title="Narratives"
                url="/narratives"
            />
            
            <MainLayout>
                <Route exact
                    path={props.match.url} component={NarrativeList}
                />
                <Route exact
                    path={`${props.match.url}/:uid`} component={NarrativeEditor}
                />
                <Route exact
                    path={`${props.match.url}/:narrativeUid/episodes/:uid`} component={EpisodeEditor}
                />
                <Route exact
                    path={[
                            `${props.match.url}/:narrativeUid/scenes/:uid`,
                            `${props.match.url}/:narrativeUid/episodes/:episodeUid/scenes/:uid`
                            ]} 
                    component={SceneEditor}
                />
                <Route exact
                    path={[
                        `${props.match.url}/:narrativeUid/scenes/:sceneUid/emotion-episodes/:uid/s/:start/e/:end`,
                        `${props.match.url}/:narrativeUid/scenes/:sceneUid/emotion-episodes/:uid`,
                        `${props.match.url}/:narrativeUid/episodes/:episodeUid/scenes/:sceneUid/emotion-episodes/:uid`,
                        `${props.match.url}/:narrativeUid/episodes/:episodeUid/scenes/:sceneUid/emotion-episodes/:uid/s/:start/e/:end`
                    ]}
                    component={EmotionEpisodeEditor}
                />
                <Route exact
                    path={[
                        `${props.match.url}/:narrativeUid/scenes/:sceneUid/emotion-episodes/:emotionEpisodeUid/participant/:uid`,
                        `${props.match.url}/:narrativeUid/episodes/:episodeUid/scenes/:sceneUid/emotion-episodes/:emotionEpisodeUid/participant/:uid`
                        ]}
                    component={ParticipantDataEditor}
                />
            </MainLayout>
        </SecuredPage>
    );
}
