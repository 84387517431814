import { faArrowLeft, faEdit, faLock, faPlus, faTrashAlt, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { emotionEpisodeDisable } from '../../../../api/actions/emotion-episode/emotion-episode-disable';
import { emotionEpisodeEnable } from '../../../../api/actions/emotion-episode/emotion-episode-enable';
import { getNarrative } from '../../../../api/actions/narrative/narrative-get';
import { sceneDisable } from '../../../../api/actions/scene/scene-disable';
import { sceneEnable } from '../../../../api/actions/scene/scene-enable';
import { getScene } from '../../../../api/actions/scene/scene-get';
import { sceneSave } from '../../../../api/actions/scene/scene-save';
import { sceneTranscoding } from '../../../../api/actions/scene/scene-transcoding';
import { Block } from '../../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../../components/shared/button/button';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Loader } from '../../../../components/shared/loader/loader';
import { SelectField } from '../../../../components/shared/select/select';
import { UploaderField } from '../../../../components/shared/uploader/uploader';
import { Video } from '../../../../components/shared/video/video';
import { getSceneFilePath } from '../../../../config/app-config';
import { secondsToTimeString, timeToSeconds } from '../../../../helpers/datetime-helpers';
import { basePerformError } from '../../../../helpers/error-helpers';
import { getFileName, isAbsoluteUrl, joinPath } from '../../../../helpers/path-helpers';
import { cutStr } from '../../../../helpers/string-helpers';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { IEpisode } from '../../../../models/episode';
import { IFile } from '../../../../models/file';
import { INarrative, NarrativeTypes } from '../../../../models/narrative';
import { IScene } from '../../../../models/scene';
import { SceneTranscodingStates } from '../../../../models/scene-transcoding-states-enum';
import { BaseTypes } from '../../../../types';
import { IDictionary } from '../../../../types/dictionary';
import { MultiError } from '../../../../types/multi-error';
import styles from './scene-editor.module.scss';

enum FormFields {
    description = 'description',
    episode = 'episode',
    start = 'start',
    end = 'end',
    file = 'file',
    fileUrl = 'fileUrl'
}

interface IForm {
    [FormFields.description]?: string,
    [FormFields.episode]?: string,
    [FormFields.start]?: string,
    [FormFields.end]?: string,
    [FormFields.file]?: IFile | null,
    [FormFields.fileUrl]?: string,
}

export function SceneEditor(props: RouteComponentProps<{narrativeUid: string, episodeUid: string, uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [scene, setScene] = useState<IScene>();
    const [narrative, setNarrative] = useState<INarrative>();

    const [transcoding, setTranscoding] = useState(false);

    const [playPos, setPlayPos] = useState<number>();
    const [startPos, setStartPos] = useState<number>();
    const [endPos, setEndPos] = useState<number>();


    const trackTranscodingProcess = async (trackedScene: IScene) => {
        try {
            if(trackedScene.uid) {
                await sceneTranscoding(trackedScene.uid);
                setTranscoding(true);
                setLoading(true);
                let checkInterval: NodeJS.Timeout | undefined = setInterval(() => {
                    (async () => {
                        setLoading(true);
                        const sc = await getScene(trackedScene.uid);
                        setTranscoding(sc.transcodingState === SceneTranscodingStates.required);
                        if(sc.transcodingState === SceneTranscodingStates.completed) {
                            if(checkInterval) {
                                clearInterval(checkInterval);
                                checkInterval = undefined;
                            }
                            try {
                                await sceneTranscoding(sc.uid);
                            }
                            catch(err) {
                                toast.error((err as MultiError).message);
                            }
                            setTranscoding(false);
                            setLoading(false);
                            setScene(undefined);
                            loadData(trackedScene.uid);
                        }
                    })().catch(() => {/** */});
                }, 10000);
            }
        }
        catch(err) {
            toast.error((err as MultiError).message);
        }
    };

    const loadData = React.useCallback(async (uid?: string) => {
        try {
            const n = await getNarrative(props.match.params.narrativeUid);
            setNarrative(n);
            if(uid || props.match.params.uid !== '_') {
                const sc = await getScene(uid || props.match.params.uid);
                setScene(sc);
                if(sc.transcodingState === SceneTranscodingStates.required) {
                    trackTranscodingProcess(sc);
                }
            }
            else {
                const newScene = { narrative: n } as IScene;
                setScene(newScene);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.narrativeUid, props.match.params.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newScene: Partial<IScene> = {
                uid: scene?.uid,
                description: values.description,
                start: timeToSeconds(values.start),
                end: timeToSeconds(values.end),
                fileName: values.file?.url || undefined,
                available: scene?.available,
                fileUrl: values.fileUrl
            };
            if(!newScene.uid) {
                newScene.narrative = { uid: props.match.params.narrativeUid } as INarrative;
            }
            if(narrative?.mediaType === NarrativeTypes.series) {
                newScene.episode = { uid: values.episode } as IEpisode;
            }
            const uid = await sceneSave(newScene, values.file?.file);
            if(!scene?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            setScene(undefined);
            await loadData(uid);
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const enableScene = async () => {
        if(!scene?.uid) return;
        try {
            await sceneEnable(scene.uid);
            setScene({...scene, available: true });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };
    const disableScene = async () => {
        if(!scene?.uid) return;
        try {
            await sceneDisable(scene.uid);
            setScene({...scene, available: false });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const enableEmotionEpisode = async (uid: string) => {
        try {
            await emotionEpisodeEnable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableEmotionEpisode = async (uid: string) => {
        try {
            await emotionEpisodeDisable(uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            description: Yup.string().trim().required().min(3).max(255),
            ...(narrative && narrative.mediaType === NarrativeTypes.series ? { episode: Yup.string().required() } : {}),
            fileUrl: Yup.string().label('File URL').trim().url(),
            start: Yup.string().trim()
                .matches(/^([0-9]{1,2}:){0,2}[0-9]+$/, 'Start must match the following: H:M:S or M:S or S')
                .test('less', 'Start should be less than End',
                    function(this: { parent: IForm }, startTime) {
                        return !timeToSeconds(this.parent.end) || (timeToSeconds(startTime || '') || 0) <= (timeToSeconds(this.parent.end || '') || 0);
                    },
                ),
            end: Yup.string().trim()
                .matches(/^([0-9]{1,2}:){0,2}[0-9]+$/, 'End must match the following: H:M:S or M:S or S')
                .test('greater', 'End should be greater than Start',
                    function(this: { parent: IForm }, endTime) {
                        return !timeToSeconds(this.parent.start) || (timeToSeconds(endTime || '') || 0) >= (timeToSeconds(this.parent.start || '') || 0);
                    },
                ),
            file: Yup.object().nullable()
                .test('valid', 'Invalid file name, in file name allowed only letters, numbers, space and symbols: _ -',
                function(this: { parent: IForm }, file: any) {
                    if(file?.file?.name) {
                        return file.file.name.match(/^[a-zA-Z0-9_ -]+.[a-zA-Z0-9_-]+$/ig);
                    }
                    return true;
                },
            
        ),
        });
    };

    const getFileUrl = () => {
        if (scene?.fileName)
            return ((isAbsoluteUrl(scene.fileName) ? scene.fileName : getSceneFilePath(scene.uid, `${getFileName(scene.fileName)}/video.m3u8`)) || '')
        else if(scene?.fileUrl)
            return scene.fileUrl

        return ''
    }

    const onProgress = (info: any) => {
        setPlayPos(Math.round(typeof info === BaseTypes.number ? info : info.playedSeconds));
    };

    const getEpisodeTitle = () => {
        if(!props.match.params.episodeUid) return 'Undefined';

        if (scene && scene.narrative) {
            const ep = scene.narrative?.episodes?.find(e => e.uid === props.match.params.episodeUid);
            if (ep && ep.name) return ep.name;
        }
        return 'Undefined';
    }

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">
                        { scene?.uid && !scene.available && (
                            <LinkButton onClick={enableScene}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                        )}
                        { scene?.uid && scene.available && (
                            <LinkButton onClick={disableScene}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                        )}
                        { scene?.uid && (
                            <LinkButton onClick={() => { /* */ }} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    { scene?.uid && (
                    <div className="form-item">
                        <label>
                            <div>
                                ID: {scene?.uid}
                            </div>
                        </label>
                    </div>)}
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    { narrative && narrative.mediaType === NarrativeTypes.series && (
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Episode
                                </div>
                                <Field
                                    component={SelectField}
                                    name={FormFields.episode}
                                    emptyTitle=""
                                    data={ narrative.episodes?.sort((e1, e2) =>
                                            (e1.season * 1000 + e1.numberInSeason)
                                            - (e2.season * 1000 + e2.numberInSeason),
                                        )
                                        .map((e: IEpisode) => ({ uid: e.uid, name: cutStr(`S-${e.season}: E-${e.numberInSeason}: ${e.name}`, 40)}))
                                    }
                                />
                            </label>
                            <div className="errors">{touched.episode && errors.episode}</div>
                        </div>
                    )}
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Start
                            </div>
                            <Field type="text" name={FormFields.start} />
                        </label>
                        <div className="errors">{touched.start && errors.start}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                End
                            </div>
                            <Field type="text" name={FormFields.end} />
                        </label>
                        <div className="errors">{touched.end && errors.end}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                File
                            </div>
                            <Field
                                component={UploaderField}
                                name={FormFields.file}
                                acceptFileTypes=".avi,.mp4,.jpeg,.jpg,.png,.gif"
                                path={getSceneFilePath(scene?.uid || '')}
                            />
                        </label>
                        <div className="errors">
                            {touched.file && errors.file}
                            {transcoding && 'Please wait, transcoding...'}
                        </div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                File Url
                            </div>
                            <Field type="text" name={FormFields.fileUrl} />
                        </label>
                        <div className="errors">{touched.fileUrl && errors.fileUrl}</div>
                    </div>
                    <div className="form-buttons">
                        <Button onClick={() => props.history.push(props.match.params.episodeUid ? `/narratives/${props.match.params.narrativeUid}/episodes/${props.match.params.episodeUid}` : `/narratives/${props.match.params.narrativeUid}`)} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { !loading && (<Button type="submit"><span>{scene?.uid ? 'Save' : 'Create'}</span></Button>)}
                    </div>
                </Form>
            </div>
        );
    };

    return (<>
        <Block className={styles.editor}>
            { scene && scene.narrative?.title && (<>
                <AddBreadcrumbsItem
                    title={scene.narrative && `Narrative: ${cutStr(scene.narrative.title)}`}
                    url={`/narratives/${props.match.params.narrativeUid}`}
                />
                {(props.match.params.episodeUid || scene.narrative.mediaType === NarrativeTypes.series) && (
                    <AddBreadcrumbsItem
                    title={`Episode: ${cutStr(scene.episode?.title ? scene.episode?.title : getEpisodeTitle())}`}
                    url={`/narratives/${props.match.params.narrativeUid}/episodes/${scene.episode ? scene.episode.uid : props.match.params.episodeUid}`}
                />

                    )}
                <AddBreadcrumbsItem
                    title={scene && scene.uid ? `Scene: ${cutStr(scene.description)}` : 'Add Scene'}
                    url={props.match.url}
                />
                <Formik
                        initialValues={{
                            description: (scene && scene.description) || '',
                            episode: ((scene && scene.episode && scene.episode.uid) || props.match.params.episodeUid || ''),
                            start: (scene && secondsToTimeString(scene.start)) || '',
                            end: (scene && secondsToTimeString(scene.end)) || '',
                            fileUrl: (scene && scene.fileUrl) || '',
                            file: (scene
                                && (
                                    (scene.fileName && { url: scene.fileName })
                                    || (scene.file && { file: scene.file })
                                )) || null,
                        }}
                        validationSchema={getValidationSchema}
                        onSubmit={handleSubmit}
                >
                    {renderForm}
                </Formik>

                <div className={styles.videoContainer}>
                    { scene && (scene.fileName || scene.fileUrl) && (<>
                        <Video
                            url={getFileUrl()}
                            controls={true}
                            onSeek={onProgress}
                            onProgress={onProgress}
                        />
                        <div className={styles.controls}>
                            <div className={styles.start}>
                                <div className={styles.value}>
                                    {
                                        startPos !== undefined
                                        ? secondsToTimeString(startPos, true)
                                        : (playPos !== undefined ? secondsToTimeString(playPos, true) : '-')}
                                </div>
                                <Button
                                    onClick={
                                        () => startPos !== undefined || (playPos || 0) > (endPos || 0) ? setStartPos(undefined) : setStartPos(playPos)
                                    }
                                    className={`small ${startPos !== undefined ? 'outline' : 'orange' }`}
                                >{startPos !== undefined ? 'Remove' : 'Set'} Start</Button>
                            </div>
                            <div className={styles.end}>
                                <div className={styles.value}>
                                    {
                                        endPos !== undefined
                                        ? secondsToTimeString(endPos, true)
                                        : (playPos !== undefined ? secondsToTimeString(playPos, true) : '-')}
                                </div>
                                <Button
                                    onClick={
                                        () => endPos !== undefined || (playPos || 0) < (startPos || 0) ? setEndPos(undefined) : setEndPos(playPos)
                                    }
                                    className={`small ${endPos !== undefined ? 'outline' : 'orange' }`}
                                >{endPos !== undefined ? 'Remove' : 'Set'} End</Button>
                            </div>
                        </div>
                    </>)}
                </div>
            </>)}
        </Block>

        { scene && scene.uid && (<>
            <div className="list-title">
                <div>Emotion Episodes: <strong>{ (scene.emotionEpisodes && scene.emotionEpisodes.length) || 0 }</strong></div>
                <div>
                    <Button
                        className="orange"
                        onClick={() => {
                            props.history.push(
                                joinPath(
                                    '/',
                                    props.match.url,
                                    `emotion-episodes/_/s/${startPos || '_'}/e/${endPos || '_'}`,
                                ),
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} /> <span>Add Emotion Episode</span>
                    </Button>
                </div>
            </div>
            { scene.emotionEpisodes && !!scene.emotionEpisodes.length && (
                <table cellPadding="0" cellSpacing="0" className={`list ${styles.list}`}>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            scene.emotionEpisodes
                            .sort((ee1, ee2) => (ee1.absoluteStart || 0) - (ee2.absoluteStart || 0))
                            .map((ee: IEmotionEpisode) => (
                                <tr key={ee.uid}>
                                    <td>
                                        <div className={styles.markContainer}>
                                            <div className={`${styles.mark} ${ee.available ? '' : styles.disabled}`}>&nbsp;</div>
                                            <div className={styles.title}>
                                                {ee.description}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {ee.status ? ee.status.toUpperCase() : ""}
                                    </td>
                                    <td>
                                        {secondsToTimeString(ee.start, true)}
                                    </td>
                                    <td>
                                        {secondsToTimeString(ee.end, true)}
                                    </td>
                                    <td>
                                        <LinkButton
                                            onClick={() => props.history.push(joinPath('/', props.match.url, 'emotion-episodes', ee.uid)) }
                                        >
                                            <FontAwesomeIcon icon={faEdit} /> Edit
                                        </LinkButton><br />
                                        { !ee.available && (<>
                                            <LinkButton onClick={() => enableEmotionEpisode(ee.uid)}>
                                                <FontAwesomeIcon icon={faUnlock} /> Enable
                                            </LinkButton><br />
                                        </>)}
                                        { ee.available && (<>
                                            <LinkButton onClick={() => disableEmotionEpisode(ee.uid)}>
                                                <FontAwesomeIcon icon={faLock} /> Disable
                                            </LinkButton><br />
                                        </>)}
                                        <LinkButton
                                            className="red"
                                            onClick={() => { /* */  }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} /> Delete
                                        </LinkButton><br />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}
        </>)}
        { loading && (<Loader />)}
    </>);
}
