import * as React from 'react';
import { getExercisesGetFiltered } from '../../../../api/actions/exercise/exercises-get-filtered';
import { getAllSkills } from '../../../../api/actions/skills-get-all';
import { Button } from '../../../../components/shared/button/button';
import { Select } from '../../../../components/shared/select/select';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { IExercise, buildResponseTree } from '../../../../models/exercise';
import { IExerciseResponse } from '../../../../models/exercise-response';
import { buildSkillsTree, ISkill } from '../../../../models/skill';
import { UnitSectionTerminologyValues } from '../../../../models/unit-section';
import { ContentAgeRating } from '../../../../models/narrative';
import { MultiError } from '../../../../types/multi-error';
import { repeat } from '../../../../helpers/array-helpers';
import styles from './emotion-episode-editor.module.scss';

interface IProps {
    emotionEpisode: IEmotionEpisode,
}

export function EmotionalEpisodeExercisesTester(props: IProps) {

    const [skills, setSkills] = React.useState<ISkill[]>();
    const [params, setParams] = React.useState<{ emotionalEpisodeUid: string, exerciseType?: string, terminology?: UnitSectionTerminologyValues, skill?: string, age: number }>();
    const [exercises, setExercises] = React.useState<IExercise[]>();
    const [error, setError] = React.useState<string>()
    const [loading, setLoading] = React.useState(false)


  const printLevel = (categories?: IExerciseResponse[], level = 0) =>
    categories &&
    categories.map((ec) => (
        <React.Fragment key={ec.text} >
            <div className={styles.answer}>
            {!!level &&
              repeat(level, () => (
                <span>
                  &raquo; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ))}
            {ec.text}
            </div>
            {printLevel(ec.children, level + 1)}
        </React.Fragment>
    ));


    const onChangeType = (v?: string) => {
        if(!params) return;
        setParams({ ...params, exerciseType: v });
    }

    const onChangeTerminology = (v?: UnitSectionTerminologyValues) => {
        if(!params) return;
        setParams({ ...params, terminology: v });
    }

    const onChangeSkill = (v?: string) => {
        if(!params) return;
        setParams({ ...params, skill: v });
    }

    const onChangeAge = (v?: string) => {
        if(!params) return;
        let value;
        if (v) value = parseInt(v);
        else value = 18;

        console.log("age value: ", value);
        setParams({ ...params, age: value });
    }

    React.useEffect(() => {
        (async() => {
            const allSkills = buildSkillsTree(await getAllSkills());
            setSkills(allSkills);
            setParams({
                emotionalEpisodeUid: props.emotionEpisode.uid,
                exerciseType: 'mult_choice',
                terminology: UnitSectionTerminologyValues.model,
                skill: undefined,
                age: 18
            });
        })().catch(() => {/** */});
    }, []);

    const load = React.useCallback(async () => {
        if(params && params.emotionalEpisodeUid && params.exerciseType && params.skill && params.terminology) {
            try {
                setLoading(true);
                let loadedExercises = await getExercisesGetFiltered(params.emotionalEpisodeUid, params.exerciseType, params.skill, params.terminology, params.age);
                loadedExercises = loadedExercises?.filter(exercise => exercise?.question);
                setExercises(loadedExercises.length ? loadedExercises : undefined);
                setError(undefined);
            }
            catch(err) {
                setExercises(undefined);
                setError((err as MultiError).message);
            }
            setLoading(false);
        }
    }, [params]);

    React.useEffect(() => {
        load();
    }, [load, params]);

    return (<div className={styles.exerciseEditor}>
        <div>
            Type:
            <Select<string, string>
                onChange={event => onChangeType(event.currentTarget.value)}
                data={['mult_choice', 'user_choice', 'open_response']}
                value={params?.exerciseType}
            />
        </div>
        <div>
            Terminology:
            <Select<UnitSectionTerminologyValues, UnitSectionTerminologyValues>
                onChange={event => onChangeTerminology(event.currentTarget.value)}
                data={[UnitSectionTerminologyValues.lay, UnitSectionTerminologyValues.model]}
                value={params?.terminology}
            />
        </div>
        <div>
            Skill:
            <Select<ISkill, string>
                onChange={event => onChangeSkill(event.currentTarget.value)}
                data={skills}
                isActive={(el: ISkill) => !el.children}
                value={params?.skill}
            />
        </div>

        <div>
            Age:
            <Select<string, string>
                onChange={event => onChangeAge(event.currentTarget.value)}
                data={Object.keys(ContentAgeRating)}
                value={ params?.age !== undefined ? '' + params?.age : '18'}
            />
        </div>


        <div className={styles.buttons}>
            <Button className="green" onClick={load}><span>Reload</span></Button>
        </div>

        {loading ? (<>
             <div className={styles.questions}>
                <div className={styles.nothing}>
                    Loading...
                </div>
            </div>
        </>) : (<>
        <div className={styles.error}>
            {error}
        </div>
        <div className={styles.questions}>
            {!error && !exercises?.length && (
                <div className={styles.nothing}>
                    Nothing to display
                </div>
            )}
            { exercises?.length && exercises.map(exercise => (
                <div key={exercise.question} className={styles.question}>
                    {exercise.heading && (
                        <div>
                            <strong>Heading:</strong> {exercise.heading}
                        </div>
                    )}
                    {exercise.initialPrompt && (
                        <div>
                            <strong>Initial Prompt:</strong> {exercise.initialPrompt}
                        </div>
                    )}
                    <div className={styles.text}>
                        <strong>Question:</strong> {exercise.question}
                    </div>
                    <div className={styles.answers}>
                        <div className={styles.answersHeader}>Answers:</div>
                        {(params?.exerciseType === 'mult_choice' || params?.age === 0 || (params?.skill !== 'emotion_labeling' && params?.skill !== 'emotion_blend_labeling' && params?.skill !== 'regulation_strategy_self' )) 
                            && exercise.responses?.length && exercise.responses.map(response => (
                            <div key={response.text + response.uid} className={styles.answer}>
                                {response.text} {(exercise.correctResponse === response.text || (exercise.correctResponseArray && exercise.correctResponseArray.includes(response.text))) && (<span className={styles.correct}>Correct response</span>)}
                                {response.feedback && (
                                    <div className={styles.answerFeedback}>
                                        Feedback: {response.feedback}
                                    </div>
                                )}
                            </div>
                        ))}

                        {(exercise?.type !== 'mult_choice') && (params?.skill === 'emotion_labeling' || params?.skill === 'emotion_blend_labeling' || (params?.skill === 'regulation_strategy_self' && params?.age > 0)) 
                            && exercise.responses?.length && 
                            printLevel(buildResponseTree(exercise.responses))}
                    </div>
                </div>
            ))}
        </div>
        </>)}

    </div>);
}
