import { faArrowLeft, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { episodeDisable } from '../../../../api/actions/episode/episode-disable';
import { episodeEnable } from '../../../../api/actions/episode/episode-enable';
import { getEpisode } from '../../../../api/actions/episode/episode-get';
import { episodeSave } from '../../../../api/actions/episode/episode-save';
import { getNarrative } from '../../../../api/actions/narrative/narrative-get';
import { Block } from '../../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../../components/shared/button/button';
import { CheckBoxWrapper } from '../../../../components/shared/checkbox/checkbox-wrapper';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Loader } from '../../../../components/shared/loader/loader';
import { SelectField } from '../../../../components/shared/select/select';
import { Tabs } from '../../../../components/shared/tabs/tabs';
import { UploaderField } from '../../../../components/shared/uploader/uploader';
import { AppConfig } from '../../../../config/app-config';
import { basePerformError } from '../../../../helpers/error-helpers';
import { joinPath } from '../../../../helpers/path-helpers';
import { getEpisodeFilePath } from '../../../../config/app-config';
import { cutStr } from '../../../../helpers/string-helpers';
import { IFile } from '../../../../models/file';
import { EntityTypes } from '../../../../models/enums/entity-types';
import { IEpisode } from '../../../../models/episode';
import { INarrative, NarrativeTypes } from '../../../../models/narrative';
import { IDictionary } from '../../../../types/dictionary';
import { EntityTasksList } from '../../../tasks/parts/entity-tasks-list';
import { ScenesList } from '../narrative/components/scenes-list/scenes-list';
import { ExternalReferencesManagement } from '../narrative/components/external-references-management/external-references-management';
import { NarrativeStatusTypes } from '../../../../models/enums/narrative-status-types';
import styles from './episode-editor.module.scss';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { getEmotionEpisodesForNarrative } from '../../../../api/actions/emotion-episode/emotion-episode-get-all-for-narrative';
import { secondsToTimeString, timeToSeconds } from '../../../../helpers/datetime-helpers';
import moment from 'moment-timezone';

interface IForm {
    name: string,
    description?: string,
    duration?: string,
    season?: number,
    numberInSeason?: number,
    url?: string,
    comingSoon?: boolean,
    status?: string,
    audioSignature?: IFile,
    catalogFile?: IFile

}

export function EpisodeEditor(props: RouteComponentProps<{narrativeUid: string, uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [tasksCount, setTasksCount] = useState<any>();
    const [episode, setEpisode] = useState<IEpisode>();
    const [narrative, setNarrative] = useState<INarrative>();
    const [allEmotionEpisodes, setAllEmotionEpisodes] = React.useState<IEmotionEpisode[]>();
    const loadData = useCallback(async (uid?: string) => {
        try {
            const n = await getNarrative(props.match.params.narrativeUid);
            setNarrative(n);
            let e = undefined;
            if(uid || props.match.params.uid !== '_') {
                e = await getEpisode(uid || props.match.params.uid)
                setEpisode(e);
            }
            else {
                e = { narrative: n } as IEpisode
                setEpisode(e);
            }
            const availableEmotionEpisodes = e?.scenes?.flatMap<IEmotionEpisode>(
              scene => (scene.emotionEpisodes) || [])?.filter((ee) => ee.available === true).sort((ee1, ee2) => ee1.start - ee2.start);


            setAllEmotionEpisodes(availableEmotionEpisodes);

        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.narrativeUid, props.match.params.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const generateAndDownloadCsv = () => {
        try{
            const csvRA = ['id,timeRanges,title'];
            allEmotionEpisodes?.forEach((i)=> {
                csvRA.push(`${i.uid},${i.start}..<${i.end},${i.description}`)
            })
            const csvString = csvRA.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.setAttribute('href', url)
            a.setAttribute('download', 'download.csv');
            a.click()
        }catch(e){
            console.error(e)
        }

    }


    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newEpisode: Partial<IEpisode> = {
                uid: episode?.uid,
                name: values.name,
                season: values.season,
                numberInSeason: values.numberInSeason,
                description: values.description,
                duration: timeToSeconds(values.duration),
                url: values.url,
                comingSoon: episode?.available ? false : values.comingSoon,
                status: values.status,
                audioSignatureFileName: values.audioSignature?.url,
                audioCatalogFileName: values.catalogFile?.url,
            };
            if(!newEpisode.uid) {
                newEpisode.narrative = { uid: props.match.params.narrativeUid } as INarrative;
            }
            const uid = await episodeSave(newEpisode, values.audioSignature && values.audioSignature.file, values.catalogFile && values.catalogFile.file);
            if(!episode?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            setEpisode(undefined);
            await loadData(uid);
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().trim().required('Please enter Name').min(3).max(50),
            description: Yup.string().trim().min(3).max(255),
            season: Yup.number().min(1).max(AppConfig.seriesMaxSeasonsCount),
            numberInSeason: Yup.number().min(1).max(AppConfig.seriesMaxNumberPerSeason),
            url: Yup.string().trim().url(),
        });
    };

    const enableEpisode = async () => {
        if(!episode?.uid) return;
        try {
            await episodeEnable(episode.uid);
            setEpisode(undefined);
            loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const disableEpisode = async () => {
        if(!episode?.uid) return;
        try {
            await episodeDisable(episode.uid);
            setEpisode({...episode, available: false });
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>


                    <div className="form-buttons">
                        { episode?.uid && !episode.available && (
                            <LinkButton onClick={enableEpisode}><FontAwesomeIcon icon={faUnlock} /> Enable</LinkButton>
                        )}
                        { episode?.uid && episode.available && (
                            <LinkButton onClick={disableEpisode}><FontAwesomeIcon icon={faLock} /> Disable</LinkButton>
                        )}
                    </div>

                  {episode?.enabledAt && (
                    <div className="form-item">
                        <label>
                            <div className="form-label">Enabled At: {moment(episode?.enabledAt).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</div> 
                        </label>
                    </div>
                    )}


                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name="name" />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                      <label>
                      <div className="form-label required">
                          Status
                      </div>
                      <Field
                          component={SelectField}
                          name="status"
                          emptyTitle=""
                          data={Object.keys(NarrativeStatusTypes).map((t: string) => ({ uid: t, name: NarrativeStatusTypes[t].toUpperCase() }))}
                      />
                      </label>
                      <div className="errors">{touched.status && errors.status}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name="description" />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>

                  {episode?.uid && (
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Duration
                            </div>
                            <Field type="text" name="duration" />
                        </label>
                    <div className="errors">{touched.duration && errors.duration}</div>
                    </div>            
                    )}

                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Season
                            </div>
                            <Field
                                component={SelectField}
                                name="season"
                                emptyTitle=""
                                data={[...Array(AppConfig.seriesMaxSeasonsCount).keys()].map((x: number) => (x + 1))}
                            />
                        </label>
                        <div className="errors">{touched.season && errors.season}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Number in Season
                            </div>
                            <Field
                                component={SelectField}
                                name="numberInSeason"
                                emptyTitle=""
                                data={[...Array(AppConfig.seriesMaxNumberPerSeason).keys()].map((x: number) => (x + 1))}
                            />
                        </label>
                        <div className="errors">{touched.numberInSeason && errors.numberInSeason}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                URL
                            </div>
                            <Field type="text" name="url" />
                        </label>
                        <div className="errors">{touched.url && errors.url}</div>
                    </div>

                        <button type='button' onClick={generateAndDownloadCsv} disabled={allEmotionEpisodes?.length === 0} className={styles.csvButton}>
                            Download Shazam Segment CSV
                        </button>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Audio Signature File
                            </div>
                            <Field
                                component={UploaderField}
                                name="audioSignature"
                                acceptFileTypes=".shazamsignature"
                                path={getEpisodeFilePath(episode?.uid || '')}
                            />
                        </label>
                        <div className="errors">{touched.audioSignature && errors.audioSignature}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Audio Catalog File
                            </div>
                            <Field
                                component={UploaderField}
                                name="catalogFile"
                                acceptFileTypes=".shazamcatalog"
                                path={getEpisodeFilePath(episode?.uid || '')}
                            />
                        </label>
                        <div className="errors">{touched.catalogFile && errors.catalogFile}</div>
                    </div>

                    {!episode?.available && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    &nbsp;
                                </div>
                                <CheckBoxWrapper label="Coming Soon">
                                    <Field type="checkbox" name="comingSoon" />
                                </CheckBoxWrapper>
                            </label>
                            <div className="errors">{touched.comingSoon && errors.comingSoon}</div>
                        </div>
                    )}

                    <div className="form-buttons">
                        <Button onClick={() => props.history.push(`/narratives/${props.match.params.narrativeUid}`)} className="gray">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>{episode?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !episode ? null : (<>
        <Tabs
            data={[
                {
                    title: 'Episode Info',
                    content: (<>
                        <br />
                        <Block className={styles.editor}>
                            { episode && narrative && (<>

                                <AddBreadcrumbsItem
                                    title={`Narrative: ${cutStr(narrative.title)}`}
                                    url={`/narratives/${props.match.params.narrativeUid}`}
                                />
                                <AddBreadcrumbsItem
                                    title={episode && episode.uid ? `Episode: ${cutStr(episode.name)}` : 'Add Episode'}
                                    url={props.match.url}
                                />

                                <Formik
                                    initialValues={{
                                        name: (episode && episode.name) || '',
                                        description: (episode && episode.description) || '',
                                        duration: (episode && secondsToTimeString(episode.duration)) || '',  
                                        season: (episode && episode.season) || undefined,
                                        numberInSeason: (episode && episode.numberInSeason) || undefined,
                                        url: (episode && episode.url) || '',
                                        comingSoon: episode?.comingSoon || false,
                                        status: episode?.status || (episode?.available ? NarrativeStatusTypes.fully_mapped : NarrativeStatusTypes.new),
                                        audioSignature: (episode
                                            && (episode.audioSignatureFileName && { url: episode.audioSignatureFileName })) || undefined,
                                        catalogFile: (episode
                                                && (episode.audioCatalogFileName && { url: episode.audioCatalogFileName })) || undefined,

                                    }}
                                    validationSchema={getValidationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {renderForm}
                                </Formik>
                            </>)}
                        </Block>

                        { episode?.uid && (<>
                            <ExternalReferencesManagement episode={episode} reloadData={loadData} />

                            <ScenesList
                                narrative={episode as INarrative}
                                addScene={() => {
                                    props.history.push(
                                        joinPath('/', props.match.url, 'scenes/_')
                                    );
                                }}
                                editScene={(uid: string) =>
                                    props.history.push(
                                        joinPath('/', props.match.url, 'scenes', uid)
                                    )
                                }
                                reloadData={loadData}
                                history={props.history}
                            />                            
                        </>)}

                    </>)
                },
                {
                    title: <>Tasks {tasksCount}</>,
                    content: (
                        <EntityTasksList
                            entity={{ uid: episode.uid, name: episode.name, type: EntityTypes.episode }}
                            setTasksCount={setTasksCount}
                            history={props.history}
                        />
                    )
                },
            ]}
        />
    </>);
}
