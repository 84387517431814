import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IParticipant } from '../../../models/participant';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetParticipant($uid: String!){
        participant(uid: $uid) {
            uid,

            emotions {
                uid,
                adjective,
                description,
                intensity,

                category {
                    uid,
                    label,
                    minAgeRating: min_age_rating
                },
            },

            regulationStrategies: regulation_strategies {
                uid,
                description,
                attempted,
                intendedOutcome: intended_outcome,
                success,

                regulationCategory: regulation_category {
                    uid,
                    name,
                },
            },

            appraisals {
                uid,
                description,
                goalCongruent: goal_congruent,
            },

            nonAppraisals: non_appraisals {
                uid,
                description,
                goalCongruent: goal_congruent,
            },

            goals {
                uid,
                description,
                congruent,
                process,
                fileName: file_name,

                appraisal {
                    uid,
                    description,
                },
            },

            regulationGoals: regulation_goals {
              uid,
              description              
            }

            nonGoals: non_goals {
                uid,
                description,
            }

            beliefs {
                uid,
                description,

                appraisal {
                    uid,
                    description,
                },
            },

            nonBeliefs: non_beliefs {
                uid,
                description,
            }

            character {
                uid,
                name,
            }

            emotionEpisode: emotion_episode {
                uid,
                description,
                start,
                end,

                scene {
                    uid,
                    description,
                    start,
                    end,

                    episode {
                        uid,
                        title
                        name
                    }

                    narrative {
                        uid,
                        title,
                    },
                },
            },
        }
    }
`;

export async function getParticipant(uid: string): Promise<IParticipant> {
    const result: FetchResult<{ participant: IParticipant }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.participant, ['__typename']);
}
