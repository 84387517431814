import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IEpisode } from '../../../models/episode';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetEpisode($uid: String!){
        episode(uid: $uid) {
            uid,
            name,
            season,
            numberInSeason: number_in_season,
            description,
            duration,
            url,
            available,
            enabledAt: enabled_at,
            comingSoon: coming_soon,
            status,
            audioSignatureFileName: audio_signature_file_name,
            audioCatalogFileName: audio_catalog_file_name,

            externalReferences: external_references {
                uid,
                serviceName: service_name,
                externalServiceUid: external_service_uid,
                externalServiceUrl: external_service_url,
                preferredSource: preferred_source,
                timeOffset: time_offset,
                paidPlansOnly: paid_plans_only
            }

            scenes {
                    description,
                    available,
                    uid,
                    start,
                    end,

                    emotionEpisodes: emotion_episodes {
                        uid,
                        description,
                        available,
                        absoluteStart: absolute_start,
                        start,
                        end,
                    }
            }
        }
    }
`;

export async function getEpisode(uid: string): Promise<IEpisode> {
    const result: FetchResult<{ episode: IEpisode }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.episode, ['__typename']);
}
