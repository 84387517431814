import { faBatteryHalf, faHeart, faSun, faTrophy, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import * as React from 'react';
import { useMemo } from 'react';
import { getUserStats } from '../../../../api/actions/user/get-user-stats';
import { getAchievementFilePath } from '../../../../config/app-config';
import { compareText, onlyUnique } from '../../../../helpers/array-helpers';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Select } from '../../../../components/shared/select/select';
import { isNotNullOrEmpty } from '../../../../helpers/common-helpers';
import { joinPath } from '../../../../helpers/path-helpers';
import { ICurriculum } from '../../../../models/curriculum';
import { IUser } from '../../../../models/user';
import { AchievementTypes } from '../../../../models/enums/achievement-types';
import { IUserStats } from '../../../../models/user-stats';
import { IUserProfile } from '../../../../models/user-profile';
import { IUserUnitStats } from '../../../../models/user-unit-stats';
import { userSendCertEmail } from '../../../../api/actions/user/user-send-cert-email';
import { basePerformError } from '../../../../helpers/error-helpers';
import { Popup } from '../../../../components/shared/popup/popup';
import { toast } from 'react-toastify';
import styles from './user-stats.module.scss';

interface IProps {
    user: IUser,
    stats: IUserUnitStats[],
    profileChangeCallback?: any
}

export function UserStats(props: IProps) {

    const getDefaultProfileID = (user: IUser) => {
        if(user.userProfiles && user.userProfiles?.length > 0) {
            const p = user.userProfiles?.find(p => p.default);
            if (p) return p.uid;
        }
        return undefined;
    }

    const [stats, setStats] = React.useState<IUserStats>();
    const [selectedAchievementUid, setSelectedAchievementUid] = React.useState<string | undefined>(undefined);
    const [selectedProfileUid, setSelectedProfileUid] = React.useState<string | undefined>(getDefaultProfileID(props.user));


    React.useEffect(() => {
        (async () => {
            if(!props.user.uid) return;
            try {
                setStats(await getUserStats(props.user.uid, selectedProfileUid));

            }
            catch(err) {
                console.log("Error fetching user stats: " + err);
                return;
            }
        })().catch(() => {/** */});
    }, [props]);


    React.useEffect(() => {
            console.log("profile change");
            try {
                if (props.profileChangeCallback) {
                    console.log("profile change callback called");
                    props.profileChangeCallback(selectedProfileUid);
                }

            }
            catch(err) {
                console.log("Error when handling profile change: " + err);
                return;
            }
    }, [selectedProfileUid]);


    const achievements = useMemo(
        () => stats?.achievementsStats?.filter((a, i, self) => onlyUnique(a, i, self, (ach) => ach.achievementUid))
            .map(ach => ({
                uid: ach.achievementUid,
                name: ach.achievementName,
                achievementType: ach.achievementType,
                available: ach.achievementAvailable,
                index: ach.achievementIndex,
                iconFileName: ach.achievementIconFileName,
            }))
            .sort((a1, a2) => a1.index - a2.index),
        [stats?.achievementsStats],
    );

    const ratingCategories = [
        { min: 0, max: 60, name: 'Practice Needed', cssClass: styles.practice_needed },
        { min: 60, max: 70, name: 'Fair', cssClass: styles.fair },
        { min: 70, max: 80, name: 'Good', cssClass: styles.good },
        { min: 80, max: 90, name: 'Very Good', cssClass: styles.very_good },
        { min: 90, max: 101, name: 'Excellent', cssClass: styles.excellent },
    ];


    const startedCurriculums = props.stats.map(s => s.curriculum)
        .filter(isNotNullOrEmpty)
        .filter((cel, i, self) => onlyUnique(cel, i, self, (el) => el.uid))
        .sort((c1, c2) => compareText(c1, c2, (cu: ICurriculum) => cu.name));

    const sendCertEmail = async (uid: string | undefined, userUid: string, toAdmin: boolean) => {
        if (!uid) return;

        try {
            const result = await userSendCertEmail(uid, userUid, toAdmin);
            if(result) { 
                toast.success('Cert Email Successfully Sent');
                setSelectedAchievementUid(undefined);
            }
        } catch (err) {
            basePerformError(err);
            setSelectedAchievementUid(undefined);
        }
    }

    const getViewer = (profileUid: string | undefined) => {
        if(!profileUid || profileUid === getDefaultProfileID(props.user)) {
            return props.user
        }
        const p = props.user.userProfiles?.find(up => up.uid == profileUid);
        return p;
    }

    const getUnitsFromStats = (curriculumUid: string) => {
        return props.stats.filter(s => s.curriculum?.uid === curriculumUid)
            .map(s => s.unit)
            .filter(isNotNullOrEmpty)
            .sort((u1, u2) => u2.index - u1.index);
    }

    const getBestScore = (unitUid: string) => {
       const sortedStats = props.stats.find(s => s.unitUid === unitUid)?.data.sort((u1, u2) => u2.unitScore - u1.unitScore);
       if (!sortedStats) return 0;

       const bestScore = sortedStats[0].unitScore;
       if(bestScore) return bestScore;
       return 0;
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>

            <div className={styles.filters}>
            <div className={`${styles.filter} ${styles.filterService}`}>
                <span>Profile:</span>
                <Select<IUserProfile, string>
                    data={props.user.userProfiles || []}
                    titleExtractor={(n) => n?.name + (n?.default ? " (Default)" : "")}
                    onChange={(e) => setSelectedProfileUid(e.target.value)}
                    value={selectedProfileUid}
                />
            </div>
            </div>

            <div className={styles.topBlock}>
                    <div className={styles.stats}>
                        <h3>Achievements</h3>
                        <div className={styles.achievementsContainer}>
                            {achievements?.map(achievement => (
                                <div key={achievement.uid} className={styles.achievement}>
                                    <div className={styles.achievementIconBadge}>
                                        {
                                            stats?.achievementsStats.reduce<number>(
                                                (count, achs) => count + ((achs.achievementUid === achievement.uid && achs.stats && achs.stats.length) || 0),
                                                0,
                                            )
                                            || 0
                                        }
                                    </div>
                                    {achievement?.iconFileName && (
                                        <img
                                            className={styles.achievementIcon}
                                            alt={achievement.name}
                                            src={joinPath(
                                                getAchievementFilePath(achievement.uid), achievement.iconFileName
                                            )}
                                        />
                                    )}
                                    <div className={styles.achievementName}>{achievement.name}</div>
                                    {(achievement?.achievementType === AchievementTypes.certification) && (
                                        <LinkButton
                                                onClick={() => setSelectedAchievementUid(achievement.uid)
                                            }
                                            >
                                                <FontAwesomeIcon icon={faEnvelope} /> Send Cert Email
                                    </LinkButton>)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={styles.topBlock}>
                    <div className={styles.stats}>
                        <h3>Metrics</h3>
                        <div className={styles.statsContainer}>
                            <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>Experience Points</div>
                                <div className={styles.statsItemVal}>
                                    <FontAwesomeIcon icon={faHeart} className={styles.icon} /> {stats && stats.points}
                                </div>
                            </div>
                            <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>Curriculum Completed</div>
                                <div className={styles.statsItemVal}>
                                    <FontAwesomeIcon icon={faBatteryHalf} className={styles.icon} />
                                    { stats && stats.totalUnits
                                        && Math.round((stats.completedUnits || 0) / (stats.totalUnits || 1) * 100)
                                    }%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.charts}>
                    <div className={styles.chartContainer}>
                        <div className={styles.header}>
                            <div className={styles.left}>
                                <div className={styles.chartTitle}>Skills</div>
                                <div className={styles.level}>Rating:
                                    { stats && ((cat) => (
                                        <span className={cat?.cssClass}> { cat?.name }&nbsp;
                                            ({ stats
                                                && Math.round((stats.skillsAnswersCorrect || 0) / (stats.skillsAnswers || 1) * 100)
                                            }%)
                                        </span>
                                        ))(
                                            ratingCategories.find(rc =>
                                                rc.min <= ((stats.skillsAnswersCorrect || 0) / (stats.skillsAnswers || 1)) * 100
                                                && rc.max > (stats.skillsAnswersCorrect || 0) / (stats.skillsAnswers || 1) * 100,
                                        ),
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.nothing}>
                            Not enought data to graph yet! Keep at it and return soon.
                            <p></p>
                        </div>
                    </div>
                    <div className={styles.chartContainer}>
                        <div className={styles.header}>
                            <div className={styles.left}>
                                <div className={styles.chartTitle}>Knowledge</div>
                                <div className={styles.level}>Rating:&nbsp;
                                    { stats && ((cat) => (
                                        <span className={cat?.cssClass}> { cat?.name }&nbsp;
                                            ({ stats
                                                && Math.round((stats.conceptsAnswersCorrect || 0) / (stats.conceptsAnswers || 1) * 100)
                                            }%)
                                        </span>
                                        ))(
                                            ratingCategories.find(rc =>
                                                rc.min <= ((stats.conceptsAnswersCorrect || 0) / (stats.conceptsAnswers || 1)) * 100
                                                && rc.max > (stats.conceptsAnswersCorrect || 0) / (stats.conceptsAnswers || 1) * 100,
                                        ),
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.nothing}>
                            Not enought data to graph yet! Keep at it and return soon.
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.topBlock}>
                <div className={styles.stats}>                    
                    <h3>Narratives Used In Training</h3>
                    {getViewer(selectedProfileUid)?.usedNarratives?.length ? (<>
                        {getViewer(selectedProfileUid)?.usedNarratives?.map(narr => (
                        <div key={narr.uid} className={styles.statsItem}>
                                <div className={styles.statsItemName}>{narr.title}</div>
                        </div>
                        ))
                    }</>) : (
                        <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>None to display</div>
                        </div>
                    )}
                </div>
            </div>


            <div className={styles.topBlock}>
                <div className={styles.stats}>                    
                    <h3>Narratives Viewed</h3>
                    {getViewer(selectedProfileUid)?.viewedNarratives?.length ? (<>
                        {getViewer(selectedProfileUid)?.viewedNarratives?.map(vnarr => (
                        <div key={vnarr.uid} className={styles.statsItem}>
                                <div className={styles.statsItemName}>{vnarr.title}</div>
                        </div>
                        ))
                    }</>) : (
                        <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>None to display</div>
                        </div>
                    )}
                </div>
            </div>


            <div className={styles.topBlock}>
                <div className={styles.stats}>                    
                    <h3>Episodes Viewed</h3>
                    {getViewer(selectedProfileUid)?.viewedEpisodes?.length ? (<>
                        {getViewer(selectedProfileUid)?.viewedEpisodes?.map(vnarr => (
                        <div key={vnarr.uid} className={styles.statsItem}>
                                <div className={styles.statsItemName}>{vnarr.title + " (" + vnarr.narrative?.title + ")"}</div>
                        </div>
                        ))
                    }</>) : (
                        <div className={styles.statsItem}>
                                <div className={styles.statsItemName}>None to display</div>
                        </div>
                    )}
                </div>
            </div>


            <div className={styles.topBlock}>
                <div className={styles.stats}>                    
                <h3>Training History</h3>

                {startedCurriculums.map(curriculum => (
                <div key={curriculum.uid}>
                    <div className={styles.curriculumName}><span>Curriculum:</span> {curriculum.name}</div>
                    {getUnitsFromStats(curriculum.uid).map(unit => (
                        <div className={styles.unitContainer} key={unit.uid}>
                            <div className={styles.unitName}><span>Unit:</span> <b>#{unit.index}</b> | {unit.name} | Best score: {getBestScore(unit.uid)}</div>
                            {props.stats.find(s => s.unitUid === unit.uid)?.data?.map(r => (
                                <div key={`stat-${unit.uid}-${r.datetime}`} className={styles.statItem}>
                                    <div className={styles.dateTime}>{r.datetime}</div>
                                    <div className={styles.points}><span>Earned Points:</span> {r.points}</div>
                                    <div className={styles.time}>
                                        <span>Spent Time:</span>
                                        {moment().startOf('day').add(r.timeSeconds, 'seconds').format('HH:mm:ss')}
                                    </div>
                                    <div className={styles.score}>
                                            <span>Score:</span> {r.unitScore}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
            </div>
            </div>

        {selectedAchievementUid && (
            <Popup
                title="Send certification email to user?"
                onClose={() => setSelectedAchievementUid(undefined)}
                buttons={[{
                    title: "Send",
                    onClick: () => sendCertEmail(selectedAchievementUid, props.user.uid, false),
                    },
                    {
                    title: "Send to Admin",
                    onClick: () => sendCertEmail(selectedAchievementUid, props.user.uid, true),
                    }]}
                >
            </Popup>
        )}

        </div>

    )
}
