import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { getParticipant } from '../../../../api/actions/participant/participant-get';
import { AddBreadcrumbsItem } from '../../../../components/shared/breadcrumbs/breadcrumbs';
import { basePerformError } from '../../../../helpers/error-helpers';
import { cutStr } from '../../../../helpers/string-helpers';
import { NarrativeTypes } from '../../../../models/narrative';
import { IParticipant } from '../../../../models/participant';
import { AppraisalManagement } from './parts/appraisal/appraisal-management';
import { BeliefManagement } from './parts/belief/belief-management';
import { EmotionManagement } from './parts/emotion/emotion-management';
import { GoalManagement } from './parts/goal/goal-management';
import { GoalProcessTypes } from '../../../../models/goal';
import { RegulationStrategyManagement } from './parts/regulation-strategy/regulation-strategy-management';

export function ParticipantDataEditor(
    props: RouteComponentProps<{narrativeUid: string, episodeUid: string, sceneUid: string, emotionEpisodeUid: string, uid: string}>,
) {
    const [participant, setParticipant] = useState<IParticipant>();

    const loadData = React.useCallback(async (uid?: string) => {
        try {
            setParticipant(await getParticipant(uid || props.match.params.uid));
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    },
    [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (<>

        { participant && participant.emotionEpisode.scene?.narrative?.title && (<>
            <AddBreadcrumbsItem
                title={`Narrative: ${participant.emotionEpisode.scene.narrative.title}`}
                url={`/narratives/${props.match.params.narrativeUid}`}
            />
            {(props.match.params.episodeUid || participant.emotionEpisode.scene?.narrative?.mediaType === NarrativeTypes.series) && (
                    <AddBreadcrumbsItem
                    title={`Episode: ${cutStr(participant.emotionEpisode.scene.episode ? participant.emotionEpisode.scene.episode.name : 'Undefined')}`}
                    url={`/narratives/${props.match.params.narrativeUid}/episodes/${participant.emotionEpisode.scene.episode ? participant.emotionEpisode.scene.episode.uid : props.match.params.episodeUid}`}
            />)}

            <AddBreadcrumbsItem
                title={`Scene: ${cutStr(participant.emotionEpisode.scene.description)}`}
                url={`/narratives/${props.match.params.narrativeUid}/scenes/${props.match.params.sceneUid}`}
            />
            <AddBreadcrumbsItem
                title={`EmEpisode: ${cutStr(participant.emotionEpisode.description)}`}
                url={`/narratives/${props.match.params.narrativeUid}/scenes/${props.match.params.sceneUid}/emotion-episodes/${props.match.params.emotionEpisodeUid}`}
            />
            <AddBreadcrumbsItem
                title={`Participant: ${cutStr(participant.character.name)}`}
                url={props.match.url}
            />
            <h2>{participant && participant.character.name }</h2>


            <EmotionManagement
                title={'Emotions'}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                emotions={participant.emotions}
                reload={loadData}
            />


            <AppraisalManagement
                title={'Appraisals'}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                appraisals={participant.appraisals}
                goals={participant.goals}
                reload={loadData}
            />


            <AppraisalManagement
                title={'False Appraisals'}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                appraisals={participant.nonAppraisals}
                goals={participant.goals}
                falseAppraisal={true}
                reload={loadData}
            />



            <BeliefManagement
                title={'Holds Beliefs'}
                holds={true}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                beliefs={participant.beliefs}
                reload={loadData}
            />



            <BeliefManagement
                title={'Does Not Hold Beliefs'}
                holds={false}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                beliefs={participant.nonBeliefs}
                reload={loadData}
            />



            <GoalManagement
                title={'Has Goals'}
                has={true}
                goal_process={GoalProcessTypes.appraisal}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                goals={participant.goals}
                reload={loadData}
            />



            <GoalManagement
                title={'Does Not Have Goals'}
                has={false}
                goal_process={GoalProcessTypes.appraisal_and_regulation}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                goals={participant.nonGoals}
                reload={loadData}
            />

            <GoalManagement
                title={'Regulation Goals'}
                has={true}
                goal_process={GoalProcessTypes.regulation}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                goals={participant.regulationGoals}
                reload={loadData}
            />

            <RegulationStrategyManagement
                title={'Regulation Strategies'}
                narrativeUid={participant.emotionEpisode.scene.narrative.uid}
                participantUid={participant.uid}
                regulationStrategies={participant.regulationStrategies}
                reload={loadData}
            />

        </>)}
    </>);
}
